import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField } from '@front/squirtle/'

import { isPositive } from '@front/squirtle/utils/validators'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4} sm={2}>
      <TextField
        name={'tips'}
        label={I18n.t('quotation.tips')}
        type={'number'}
        validate={isPositive}
        InputProps={{
          endAdornment: I18n.t('prefix.percent'),
          inputProps: { style: { textAlign: 'right' } }
        }
        }
      />
    </Grid>
    <Grid item xs={4} sm={2}>
      <TextField
        name={'discount'}
        label={I18n.t('discount.other')}
        type={'number'}
        validate={isPositive}
        InputProps={{
          endAdornment: I18n.t('prefix.percent'),
          inputProps: { style: { textAlign: 'right' } }
        }
        }
      />
    </Grid>
  </Grid>

const Tips = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('quotation.tips')} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(Tips)
