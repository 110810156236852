import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, SingleOptionSelectorField } from '@front/squirtle/'

const Row1 = props =>
    <Grid container item xs={12} spacing={1}>
      <Grid item xs>
        <SingleOptionSelectorField
          name={'immediate_order_enabled'}
          optionLabel={I18n.t('specificparameter.immediate_order_enabled')}
          clearOnEmpty={false}
        />
      </Grid>
      <Grid item xs>
        <SingleOptionSelectorField
          name={'planned_order_enabled'}
          optionLabel={I18n.t('specificparameter.planned_order_enabled')}
          clearOnEmpty={false}
        />
      </Grid>
      <Grid item xs>
        <SingleOptionSelectorField
          name={'dest_mandatory'}
          optionLabel={I18n.t('address.dest_mandatory')}
          clearOnEmpty={false}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name={'min_delay'}
          label={I18n.t('specificparameter.min_delay')}
          type='number'
        />
      </Grid>
      <Grid item xs>
        <TextField
          name={'max_delay'}
          label={I18n.t('specificparameter.max_delay')}
          type='number'
        />
      </Grid>
    </Grid >

const BookingSection = ({ formattedSuspension, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('order.taking')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(BookingSection)
